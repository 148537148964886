import { useMediaQuery } from "@chakra-ui/react";
import classNames from "classnames";
import React, { useMemo, useRef, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import useScrollPosition from "@hooks/useScrollPosition";
import { breakpoints } from "@styles/constants";

import styles from "./index.module.css";

const FilterBarWrapper = ({ children }) => {
  const { scrollPosition, containerRef } = useScrollPosition();
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.md})`);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dragStartX = useRef<number>(0);
  const containerScrollStart = useRef<number>(0);

  const { atStart, atEnd } = useMemo(() => {
    const container = containerRef.current;
    return container
      ? {
          atStart: scrollPosition <= 0,
          atEnd:
            scrollPosition >= container.scrollWidth - container.clientWidth,
        }
      : { atStart: true, atEnd: true };
  }, [scrollPosition]);

  const isOverflowing = useMemo(() => {
    const container = containerRef.current;
    return container ? container.scrollWidth > container.clientWidth : false;
  }, [containerRef.current?.scrollWidth]);

  const handleScrollOnClick = (
    scrollAmount: number,
    scrollDirection: "left" | "right"
  ) => {
    const container = containerRef.current;

    if (container) {
      container.scrollTo({
        left:
          scrollDirection === "left"
            ? container.scrollLeft - scrollAmount
            : container.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleMouseEvent = (event: React.MouseEvent, type: "down" | "move") => {
    const container = containerRef.current;

    if (!isOverflowing || !container) return;

    if (type === "down") {
      dragStartX.current = event.clientX;
      containerScrollStart.current = container.scrollLeft;
      setIsDragging(true);
    } else {
      if (!isDragging) return;

      const delta = event.clientX - dragStartX.current;
      container.scrollTo({
        left: containerScrollStart.current - delta,
        behavior: "instant",
      });
    }
  };

  const handleTouchEvent = (
    event: React.TouchEvent,
    type: "start" | "move"
  ) => {
    const container = containerRef.current;

    if (!isOverflowing || !container || !isMobile) return;

    if (type === "start") {
      dragStartX.current = event.touches[0].clientX;
      containerScrollStart.current = containerRef.current.scrollLeft;
    } else {
      const delta = event.touches[0].clientX - dragStartX.current;
      container.scrollTo({
        left: containerScrollStart.current - delta,
        behavior: "instant",
      });
    }
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.grab]: isOverflowing,
        [styles.grabbing]: isDragging,
      })}
      onMouseDown={(event) => handleMouseEvent(event, "down")}
      onMouseMove={(event) => handleMouseEvent(event, "move")}
      onMouseUp={() => setIsDragging(false)}
      onMouseLeave={() => setIsDragging(false)}
      onTouchStart={(event) => handleTouchEvent(event, "start")}
      onTouchMove={(event) => handleTouchEvent(event, "move")}
    >
      {isOverflowing && !isMobile && (
        <BsChevronLeft
          className={styles.chevron}
          size={14}
          onClick={() => handleScrollOnClick(250, "left")}
        />
      )}
      <div
        ref={containerRef}
        className={classNames(styles.innerContainer, {
          [styles.overflowStart]: isMobile && isOverflowing && !atStart,
          [styles.overflowEnd]: isMobile && isOverflowing && !atEnd,
        })}
      >
        {children}
      </div>
      {isOverflowing && !isMobile && (
        <BsChevronRight
          className={styles.chevron}
          size={14}
          onClick={() => handleScrollOnClick(250, "right")}
        />
      )}
    </div>
  );
};

export default FilterBarWrapper;
