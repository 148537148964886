import { Box, useTheme } from "@chakra-ui/react";
import { FormattedDate, FormattedMessage, useIntl } from "@react-intl";
import { format, isValid } from "date-fns";
import Link from "next/link";
import React from "react";
import Media from "react-media";
import { generatePath } from "react-router";

import { TaxedMoney } from "@components/containers";
import { paths } from "@paths";
import { DefaultTheme } from "@styles/themes";
import { commonMessages, translateOrderStatus } from "@temp/intl";

import { Thumbnail } from "..";
import * as S from "./styles";
import { IProps } from "./types";

const header = (isDesktop: boolean) => (
  <S.HeaderRow>
    <S.IndexNumber>
      <FormattedMessage defaultMessage="Number" />
    </S.IndexNumber>
    <S.ProductsOrdered />
    {isDesktop ? (
      <S.DateOfOrder>
        <FormattedMessage defaultMessage="Date of Order" />
      </S.DateOfOrder>
    ) : (
      <S.DateOfOrder>
        <FormattedMessage defaultMessage="Date Period" />
      </S.DateOfOrder>
    )}
    {isDesktop && (
      <S.DateOfOrder>
        <FormattedMessage defaultMessage="Rental Start" />
      </S.DateOfOrder>
    )}
    {isDesktop && (
      <S.DateOfOrder>
        <FormattedMessage defaultMessage="Rental End" />
      </S.DateOfOrder>
    )}
    {isDesktop && (
      <>
        <S.Value>
          <FormattedMessage defaultMessage="Value" />
        </S.Value>
      </>
    )}
    <S.Status>
      <FormattedMessage {...commonMessages.status} />
    </S.Status>
  </S.HeaderRow>
);

export const OrderTable: React.FC<IProps> = ({ orders, isGuest }: IProps) => {
  const theme: DefaultTheme = useTheme();
  const intl = useIntl();

  return (
    <S.Wrapper>
      <Media
        query={{
          minWidth: theme.breakpoints.lg,
        }}
      >
        {(isDesktop: boolean) => (
          <>
            <S.Row>{header(isDesktop)}</S.Row>
            {orders.map(
              ({
                created,
                token,
                number,
                lines,
                total,
                statusDisplay,
                reservations,
              }) => {
                const date = new Date(created);
                const rentalStart = reservations?.[0]?.rentalStartDate;
                const rentalEnd = reservations?.[0]?.rentalEndDate;
                const rentalStartMobile =
                  isValid(new Date(rentalStart)) &&
                  format(new Date(rentalStart), "dd LLL");
                const rentalEndMobile =
                  isValid(new Date(rentalEnd)) &&
                  format(new Date(rentalEnd), "dd LLL yyyy");

                return (
                  <Link
                    href={generatePath(
                      isGuest
                        ? paths.guestOrderDetail
                        : paths.accountOrderOverview,
                      { token }
                    )}
                    key={number!}
                  >
                    <S.Row
                      data-test="orderEntry"
                      data-test-id={number!}
                      key={number!}
                    >
                      <S.IndexNumber>{number!}</S.IndexNumber>
                      <S.ProductsOrdered>
                        {lines
                          .slice(0, 5)
                          .filter((line) => line!.variant)
                          .map((line) => (
                            <Link
                              href={generatePath(paths.product, {
                                slug: line!.variant!.product.slug,
                              })}
                              key={line!.variant!.product.id}
                            >
                              <a>
                                <S.ThumbnailWrapper>
                                  <Thumbnail
                                    heightPixels={70}
                                    widthPixels={40}
                                    source={line!}
                                  />
                                </S.ThumbnailWrapper>
                              </a>
                            </Link>
                          ))}
                      </S.ProductsOrdered>
                      {isDesktop ? (
                        <S.DateOfOrder>
                          <FormattedDate value={date} />
                        </S.DateOfOrder>
                      ) : (
                        <S.DateOfOrder>
                          {rentalStartMobile && rentalEndMobile
                            ? `${rentalStartMobile} to ${rentalEndMobile}`
                            : ``}
                        </S.DateOfOrder>
                      )}
                      {isDesktop && (
                        <S.DateOfOrder>
                          {rentalStart ? (
                            <FormattedDate value={rentalStart} />
                          ) : (
                            <Box>-</Box>
                          )}
                        </S.DateOfOrder>
                      )}
                      {isDesktop && (
                        <S.DateOfOrder>
                          {rentalEnd ? (
                            <FormattedDate value={rentalEnd} />
                          ) : (
                            <Box>-</Box>
                          )}
                        </S.DateOfOrder>
                      )}
                      {isDesktop ? (
                        <>
                          <S.Value>
                            <TaxedMoney taxedMoney={total} />
                          </S.Value>
                        </>
                      ) : (
                        ""
                      )}
                      <S.Status>
                        {translateOrderStatus(statusDisplay!, intl)}
                      </S.Status>
                    </S.Row>
                  </Link>
                );
              }
            )}
          </>
        )}
      </Media>
    </S.Wrapper>
  );
};
