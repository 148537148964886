import { Button, Stack, StackProps } from "@chakra-ui/react";
import { NextRouter, useRouter } from "next/router";
import React from "react";

interface NoRecommendedProductsProps extends Omit<StackProps, "as"> {
  buttonVariant?: "link" | "outline" | "ghost" | "solid" | "unstyled";
}

export const NoRecommendedProducts: React.FC<NoRecommendedProductsProps> = ({
  buttonVariant = "outline",
  ...props
}) => {
  const router: NextRouter = useRouter();

  return (
    <Stack boxShadow="base" padding="1rem" {...props}>
      <span>This item is unavailable right now.</span>
      <Button
        onClick={() => router.push("/search?keyword=")}
        minWidth="250px"
        variant={buttonVariant}
      >
        Find More Items
      </Button>
    </Stack>
  );
};
