import { Box } from "@chakra-ui/react";
import React from "react";

import { MobileNavLoginPanel } from "../../atoms";
import { MobileNavAccountTools } from "..";
import { IProps } from "./types";

export const MobileNavAccount: React.FC<IProps> = ({
  user,
  login,
  signOut,
  hideNav,
}) => (
  <Box h="100%" display="flex" flexDirection="column">
    {user ? (
      <>
        <Box px="1.3rem" py="1rem" fontWeight="bold" textTransform="uppercase">
          {user?.firstName} {user?.lastName}
        </Box>
        <MobileNavAccountTools
          user={user}
          hideNav={hideNav}
          signOut={signOut}
          isStaff={!!user?.isStaff}
        />
      </>
    ) : (
      <MobileNavLoginPanel login={login} />
    )}
  </Box>
);
