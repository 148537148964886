import styled from "@emotion/styled";

import { minMedia } from "@styles";
import {
  breakpoints,
  lenderNameSize,
  noticeFontSize,
  theme,
} from "@styles/constants";

// export const StyledLender = styled.ul`
//   padding: 0;
//   margin: 35px 0 25px 0;
//   list-style: none;
//   text-align: center;
//   display: table;
//   width: 100%;
//   @media (min-width: ${breakpoints.lg}) {
//     display: block;
//   }
//   li {
//     display: block;
//     margin: 8px 0;
//     text-align: center;
//     img {
//       height: 42px;
//       display: inline-block;
//     }
//     &:nth-child(1) {
//       margin: 16px 0 8px 0;
//     }
//     &:nth-child(2) {
//       display: flex;
//       justify-items: center;
//       justify-content: center;
//     }
//     @media (min-width: ${breakpoints.lg}) {
//       display: inline-block;
//       margin: 0 15px;
//       vertical-align: middle;
//       &:nth-child(1) {
//         margin: 0 15px;
//       }
//       &:nth-child(2) {
//         display: inline-block;
//       }
//     }
//   }
// `;
export const StyledLenderName = styled.span`
  padding: 1rem;
  font-weight: 600;
  font-size: 24px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 30px;
    color: ${theme.black};
  }
`;

export const StyledLenderLink = styled.div`
  text-align: center;
  margin-bottom: 48px;
  a {
    display: inline-block;
    width: 184px;
    line-height: 35px;
    height: 35px;
    font-size: ${noticeFontSize};
    color: ${theme.black};
    border: 1px solid ${theme.black};
    text-transform: uppercase;
    transition: all linear 0.2s;
    &:hover {
      background-color: ${theme.black};
      color: ${theme.white};
    }
  }
  @media (min-width: ${breakpoints.lg}) {
  }
`;

// export const StyledLenderImageContainer = styled.div`
//   position: relative;
//   height: 50px;
//   width: 50px;

//   li {
//     display: flex;
//     justify-content: center;
//     justify-items: center;
//   }

//   @media (min-width: ${breakpoints.lg}) {
//   }
// `;

export const LenderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProductListHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: fit-content;
  gap: 0.5rem;

  ${minMedia.mediumScreen`
      min-width: 160px;
  `};

  ${minMedia.smallScreen`
      min-width: 160px;
  `};
`;

export const ProductListHeaderText = styled.p`
  font-weight: 800;
  font-size: ${lenderNameSize};
`;

export const ProductLenderContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin: 0 auto;
  max-width: fit-content;
  min-width: 200px;
  padding: 1rem 0px;
  align-items: center;

  ${minMedia.smallScreen`
  flex-direction: row;
`};
`;

export const ProductLenderInnerContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row:
`;
