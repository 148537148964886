import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import React from "react";

export const P: React.FC<BoxProps> = ({ children, ...props }) => {
  const styles = useStyleConfig("p");

  return (
    <Box as="p" __css={styles} {...props}>
      {children}
    </Box>
  );
};
