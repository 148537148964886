import { imageLoaderUri } from "@app/constants";

export const imageLoader = ({ src, width, quality }) => {
  const path = /^http.*/.test(src) ? new URL(src).pathname : src;
  return `${imageLoaderUri}/_next/image/?url=${path}&w=${width}&q=${
    quality || 75
  }`;
};

export const greyDataPixel =
  "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM84gMAAdoBEhfcOz0AAAAASUVORK5CYII=";
