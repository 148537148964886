import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { FormattedMessage } from "@react-intl";
import dayjs from "dayjs";
import React from "react";

import { lenderRatingOptions } from "@app/constants";
import { Button, ChipV2, DropdownSelect } from "@components/atoms";
import { defaultTheme } from "@styles";
import { commonMessages } from "@temp/intl";

import FilterBarWrapper from "./FilterBarWrapper";
import { IProps } from "./types";

export const ProductListHeaderFilterBar: React.FC<IProps> = ({
  numberOfProducts = 0,
  openFiltersMenu,
  activeSortOption,
  activeFiltersAttributes = [],
  sortOptions,
  onChange,
  locationFilter,
  onLocationFilterChange,
  pricesFilter,
  onPricesFilterChange,
  deliveryFilter,
  setDeliveryFilter,
  onCloseFilterAttribute,
  mobile = true,
  showFilters,
  lenderView,
  title,
  keyword,
  setKeyword,
  lenderRating,
  displayLoader,
}: IProps) => (
  <Box
    backgroundColor={defaultTheme.colors.white}
    position="sticky"
    top={mobile ? 52.3 : 68}
    zIndex={10}
    w="full"
  >
    <Box maxWidth="75rem" marginX="auto">
      <Flex
        fontSize={defaultTheme.typography.smallFontSize}
        marginTop={lenderView ? 0 : "1rem"}
        align="center"
        minHeight="48px"
        maxHeight="48px"
      >
        <Box>
          <Button
            visibility={!showFilters ? "visible" : "hidden"}
            fontSize={defaultTheme.typography.smallFontSize}
            display="flex"
            alignItems="center"
            cursor="pointer"
            textTransform="uppercase"
            onClick={openFiltersMenu}
            testingContext="filtersButton"
            width="90px"
            height="25px"
            padding="0.2rem 0.4rem 0.2rem 0.4rem"
          >
            <FormattedMessage {...commonMessages.filterHeader} />{" "}
          </Button>
        </Box>
        <Spacer />
        {!displayLoader ? (
          <Text
            align="center"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            padding="10px"
          >
            {title || "Listings"}
            :&nbsp;
            {numberOfProducts.toLocaleString()}
          </Text>
        ) : (
          <Box />
        )}
        <Spacer />
        <Box textTransform="uppercase" minWidth="85px">
          <DropdownSelect
            onChange={onChange}
            options={sortOptions}
            value={sortOptions.find(
              (option) => option.value === activeSortOption
            )}
            mobile={mobile}
          />
        </Box>
      </Flex>

      <FilterBarWrapper>
        {keyword && (
          <ChipV2
            key="search"
            label={keyword}
            onRemove={() => setKeyword(undefined)}
          />
        )}
        {activeFiltersAttributes.map(
          ({ attributeSlug, valueName, valueSlug }) => (
            <ChipV2
              key={valueSlug}
              onRemove={() => onCloseFilterAttribute(attributeSlug, valueSlug)}
              label={`${attributeSlug === "size" ? "Size " : ""}${valueName}`}
            />
          )
        )}
        {lenderRating && (
          <ChipV2
            key={`${lenderRating?.[0]}-lender-rating`}
            onRemove={() =>
              onCloseFilterAttribute("lenderRating", lenderRating?.[0])
            }
            label={
              lenderRatingOptions?.find(
                ({ slug }) => slug === lenderRating?.[0]
              )?.name || ""
            }
          />
        )}
        {pricesFilter?.map(
          (price) =>
            price?.slug && (
              <ChipV2
                key={price.slug}
                onRemove={() =>
                  onPricesFilterChange(
                    pricesFilter.filter((p) => p.slug !== price.slug)
                  )
                }
                label={
                  price.slug === "under-100"
                    ? `Under $100`
                    : price.slug === "over-250"
                    ? `Over $250`
                    : `$${price?.gte} - $${price?.lte}`
                }
              />
            )
        )}
        {locationFilter &&
          locationFilter.map(
            (item) =>
              item.slug && (
                <ChipV2
                  key={item.name}
                  onRemove={() => onLocationFilterChange(item)}
                  label={item.name}
                />
              )
          )}
        {deliveryFilter && deliveryFilter.date && (
          <ChipV2
            key="delivery-date-chip"
            onRemove={() => setDeliveryFilter({ ...deliveryFilter, date: "" })}
            label={`Delivery: ${dayjs(deliveryFilter.date).format(
              "DD/MM/YYYY"
            )}`}
          />
        )}
        {deliveryFilter && deliveryFilter.postCode && (
          <ChipV2
            key="delivery-postcode-chip"
            onRemove={() =>
              setDeliveryFilter({ ...deliveryFilter, postCode: "" })
            }
            label={`Post Code: ${deliveryFilter.postCode}`}
          />
        )}
      </FilterBarWrapper>
    </Box>
  </Box>
);
