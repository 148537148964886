import React, { useContext } from "react";

import { ShopContext } from "@components/legacy/ShopProvider/context";

import { IProps } from "./types";

export const Money: React.FC<IProps> = ({
  money,
  defaultValue,
  showDecimals = true,
  ...props
}: IProps) => {
  if (!money) {
    return <span {...props}>{defaultValue}</span>;
  }
  const { defaultCountry, currency } = useContext(ShopContext);

  const { amount } = money;
  return (
    <span {...props}>
      {money.currency
        ? amount.toLocaleString(`en-${defaultCountry?.code || "AU"}`, {
            style: "currency",
            currency,
            maximumFractionDigits: showDecimals ? 2 : 0,
            minimumFractionDigits: showDecimals ? 2 : 0,
          })
        : amount.toString()}
    </span>
  );
};

Money.displayName = "Money";
export default Money;
