import React from "react";

import { IconButton, Tile } from "@components/atoms";
import { keycloakRealm } from "@temp/constants";

import * as S from "./styles";

export const PasswordTile: React.FC = () => {
  const changePasswordRoute = `/auth/realms/${keycloakRealm}/account/password`;

  return (
    <S.TileWrapper>
      <Tile>
        <S.Wrapper>
          <S.Content>
            <a href={changePasswordRoute}>
              <IconButton
                testingContext="editDetailsButton"
                name="edit"
                size={22}
              />
              Click here to update your personal details or password
            </a>
          </S.Content>
        </S.Wrapper>
      </Tile>
    </S.TileWrapper>
  );
};
