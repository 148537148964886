import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DefaultTheme, media } from "@styles";
import { breakpoints } from "@styles/constants";

export const Wrapper = styled.div``;

export const AttributeList = styled.ul`
  ${({
    theme,
    isShippingMethodsList,
  }: {
    theme?: DefaultTheme;
    isShippingMethodsList?: boolean;
  }) => {
    const { typography } = theme || {};

    return css`
      padding: 0;
      margin: 0;

      display: grid;
      grid-template-columns: repeat(2, minmax(200px, 1fr));
      grid-template-rows: auto auto;
      grid-auto-flow: row dense;
      grid-column-gap: 13px;
      grid-row-gap: 10px;
      justify-items: end;

      li {
        font-size: ${typography?.smallFontSize};
        word-break: break-word;
      }

      li:nth-child(even) {
        justify-self: start;
      }
      @media (max-width: ${breakpoints.md}) {
        grid-template-columns: repeat(2, minmax(183px, 1fr));
        display: ${isShippingMethodsList ? "flex" : "grid"};
        flex-direction: column;
        li {
          max-width: ${!isShippingMethodsList ? "200px" : "none"};
          text-align: left;
        }
        margin-bottom: ${isShippingMethodsList ? "20px" : "0"};
        padding-right: 20px;
      }
    `;
  }}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;

  ${media.mediumScreen`
    margin-bottom: 38px;
  `};
`;

export const SectionTitle = styled.h2<{
  active?: boolean;
}>`
  ${({ theme, active }) => {
    const { typography, colors } = theme as DefaultTheme;

    return css`
      letter-spacing: -0.02em;
      color: ${active && colors.tabTitle};
      font-weight: ${typography.boldFontWeight};
      font-size: ${typography.h4FontSize};
      line-height: 1.5em;
      padding-bottom: 10px;

      ${media.mediumScreen`
        font-size: ${typography.h3FontSize};
        padding-bottom: 12px;
      `};
    `;
  }}
`;

export const AttributeName = styled.span`
  color: ${(props) => props.theme.colors.listAttributeName};
`;
