import { ShopMenusQuery_mainMenu_items } from "@graphql/gqlTypes/ShopMenusQuery";

const getParentSlugs = (items: ShopMenusQuery_mainMenu_items[]) => {
  const result = {};
  const getSlug = (item) =>
    item.category?.slug ||
    item.page?.slug ||
    (item.parent ? "" : item.name.toLowerCase());
  items.forEach((item) => {
    item?.children?.forEach((child) => {
      result[child?.id] = {
        parentSlug: getSlug(item),
        parentName: item.name,
      };
      child?.children?.forEach((grandchild) => {
        result[grandchild.id] = {
          parentSlug: getSlug(child) || getSlug(item),
          parentName: child?.name,
        };
      });
    });
  });
  return result;
};
export const legacyCategories = ["dresses", "clothing", "accessories"];
export const designerSlugSuffix = "-hire";

const getUrlFromItem = (item: ShopMenusQuery_mainMenu_items, parents: any) => {
  const parentSlug = parents[item.id]?.parentSlug;
  const parentName = parents[item.id]?.parentName.toLowerCase();

  if (item.category) {
    if (legacyCategories.find((c) => c === item?.category?.slug)) {
      return `/${item.category.slug}`;
    }
    if (parentSlug && legacyCategories.find((c) => c === parentSlug)) {
      return `/${parentSlug}/${item.category.slug}`;
    }
    return `/category/${item.category.slug}`;
  }
  if (item.collection) {
    return `/collection/${item.collection.slug}`;
  }
  if (item.page) {
    const legacyPages = ["designers", "trends", "occasions", "size", "lengths"];
    if (legacyPages.find((p) => p === item?.page?.slug)) {
      return `/${item.page.slug}`;
    }
    if (parentSlug && legacyPages.find((p) => p === parentSlug)) {
      let pageParentSlug = parentSlug;

      const slugSuffix =
        pageParentSlug === "designers" ? designerSlugSuffix : "";

      // Special case for size
      if (parentSlug === "size") {
        pageParentSlug = "dresses";
      }
      return `/${pageParentSlug}/${item.page.slug}${slugSuffix}`;
    }

    // Special cases for dresses
    if (parentSlug === "dresses") {
      if (parentName.startsWith("dress code")) {
        return `/occasions/${item.page.slug}`;
      }

      if (parentName.startsWith("length")) {
        return `/lengths/${item.page.slug}`;
      }

      if (parentName.startsWith("trend")) {
        return `/trends/${item.page.slug}`;
      }
    }

    return `/${item.page.slug}`;
  }
  return item.url;
};

export const getMappedItems = (items: ShopMenusQuery_mainMenu_items[]) => {
  const parents = getParentSlugs(items);
  return items.map((item) => ({
    ...item,
    legacyUrl: getUrlFromItem(item, parents),
    children: item?.children?.map((child) => ({
      ...child,
      legacyUrl: getUrlFromItem(child, parents),
      children: child?.children?.map((grandchild) => ({
        ...grandchild,
        legacyUrl: getUrlFromItem(grandchild, parents),
      })),
    })),
  }));
};
