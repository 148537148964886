import styled from "@emotion/styled";

import { theme, TitleLargeSize } from "@styles/constants";

export const ListProduct = styled.div`
  margin: 48px 0 0 0;
  background-color: ${theme.black};
  padding: 46px 0 46px 0;
`;
export const Title = styled.h2`
  font-size: ${TitleLargeSize};
  line-height: 20px;
  color: ${theme.white};
  text-align: center;
  margin: 10px 0 26px 0;
`;
