import React from "react";
import { ReactSVG } from "react-svg";

import { ErrorMessage, Input } from "@components/atoms";

import * as S from "./styles";
import { IProps } from "./types";

import closeImg from "images/x.svg";

export const TextField: React.FC<IProps> = ({
  errors,
  helpText,
  noSpacer,
  inlineError,
  onClose,
  errorPosition,
  ...rest
}: IProps) => {
  const hasErrors = !!(errors && errors.length);

  return (
    <>
      <S.TextField style={noSpacer ? { marginBottom: "4px" } : {}}>
        <Input {...rest} error={hasErrors} />
        <S.ErrorMessages
          style={
            inlineError
              ? { position: "relative" }
              : errorPosition
              ? { position: errorPosition }
              : {}
          }
        >
          <ErrorMessage errors={errors} />
          {helpText && <S.HelpText>{helpText}</S.HelpText>}
        </S.ErrorMessages>
        {onClose && (
          <ReactSVG
            style={{
              position: "absolute",
              top: "15px",
              left: "100%",
              transform: "translate(-30px, 0)",
            }}
            src={closeImg.src}
            onClick={onClose}
          />
        )}
      </S.TextField>
    </>
  );
};
