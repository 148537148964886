import dayjs from "dayjs";
import React from "react";

import { checkoutMessages } from "@app/intl";
import { isPurchase } from "@utils/misc";

import { NextImage } from "../NextImage";
import * as S from "./styles";
import { IProps } from "./types";

/**
 * Row with product to display in cart summary.
 */
const CartSummaryRow: React.FC<IProps> = ({
  sku,
  name,
  thumbnail,
  bookedRange,
  rentalPeriod,
}: IProps) => {
  const daysBooked =
    rentalPeriod && bookedRange && dayjs(bookedRange.from).format("DD MMM");
  return (
    <S.Wrapper data-test="cartSummary" data-test-id={sku}>
      <S.Name data-test="name">{name}</S.Name>
      <S.Price data-test="price">
        {daysBooked
          ? isPurchase(rentalPeriod)
            ? `${checkoutMessages.deliveryDate.defaultMessage}: ${daysBooked}`
            : `${checkoutMessages.rentalStart.defaultMessage}: ${daysBooked} (${rentalPeriod} days)`
          : ""}
      </S.Price>
      <S.Photo>
        <NextImage
          heightPixels={100}
          widthPixels={70}
          url={thumbnail?.url}
          alt={thumbnail?.alt}
          layout="fill"
        />
      </S.Photo>
    </S.Wrapper>
  );
};

export { CartSummaryRow };
