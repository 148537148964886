import { useAuth } from "@the-volte/svc-core-sdk";

import { siteUrl, ssrMode } from "@app/constants";

type RedirectQueryType = {
  [key: string]: string;
};

export default function useLogin() {
  const { externalLogin } = useAuth();

  const externalAuthPlugin = "keycloak.openidconnect";

  const getAuthUrl = async (
    redirectQuery?: RedirectQueryType,
    redirectPath?: string
  ) => {
    if (!ssrMode) {
      const queryString = new URLSearchParams(redirectQuery);
      const redirectUrl = redirectPath
        ? `${siteUrl}${redirectPath}`
        : window.location.href;
      const redirectUri = redirectQuery
        ? `${redirectUrl}?${queryString.toString()}`
        : redirectUrl;
      const payload = {
        pluginId: externalAuthPlugin,
        input: JSON.stringify({ redirectUri }),
      };
      const {
        data: {
          externalAuthenticationUrl: { authenticationData },
        },
      } = await externalLogin(payload);
      const { authorizationUrl } = JSON.parse(authenticationData);
      return authorizationUrl;
    }
  };

  const login = async (
    redirectQuery?: RedirectQueryType,
    redirectPath?: string
  ) => {
    if (!ssrMode) {
      const authorizationUrl = await getAuthUrl(redirectQuery, redirectPath);
      window.location.href = authorizationUrl;
    }
  };

  const register = async (
    redirectQuery?: RedirectQueryType,
    redirectPath?: string
  ) => {
    if (!ssrMode) {
      const authorizationUrl = await getAuthUrl(redirectQuery, redirectPath);
      window.location.href = authorizationUrl.replace(
        "auth?",
        "registrations?"
      );
    }
  };

  return { externalAuthPlugin, login, register };
}
