import { Grid, GridItem, Stack } from "@chakra-ui/react";
import React from "react";

import { ProductList_products_edges } from "@app/views/gqlTypes/ProductList";
import { ProductTile } from "@components/molecules";

import { NoRecommendedProducts } from "./NoRecommendedProducts";

type ProductRecommendationsProps = {
  recommendations: ProductList_products_edges[];
  category?: string;
};

export const ProductRecommendations: React.FC<ProductRecommendationsProps> = ({
  recommendations,
  category,
}) => {
  const hasProducts = recommendations?.length > 0;

  return (
    <Stack height="100%">
      {hasProducts && (
        <span style={{ color: "GrayText", fontSize: "14px" }}>
          This item is unavailable right now. The following{" "}
          {category || "items"} match your size and style and are ready to Rent
          or Buy:
        </span>
      )}
      {hasProducts ? (
        <Grid
          boxShadow="base"
          gap="1.5rem"
          gridTemplateColumns="1fr 1fr"
          padding="1rem"
        >
          {recommendations?.map((item) => (
            <GridItem key={item.node.id}>
              <ProductTile
                as="h3"
                product={item.node}
                productTileWidth="auto"
                thumbnailHeight="250px"
                stackDesignerAndSize
              />
            </GridItem>
          ))}
        </Grid>
      ) : (
        <NoRecommendedProducts />
      )}
    </Stack>
  );
};
