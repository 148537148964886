import { Box } from "@chakra-ui/react";
import React from "react";

import { WishlistHeart } from "@components/atoms/WishlistHeart";

type WishlistButtonProps = {
  toggleWishlist: () => void;
  isInWishlist: boolean;
  productTile?: boolean;
};

function WishlistButton({
  isInWishlist,
  toggleWishlist,
  productTile = false,
}: WishlistButtonProps) {
  const wrapper = (content) =>
    productTile ? <Box position="relative">{content}</Box> : <>{content}</>;
  return wrapper(
    <Box
      position={productTile ? undefined : "absolute"}
      right={productTile ? 2 : [6, 6, 6, 8, 10]}
      top={productTile ? 1 : 4}
      zIndex={1}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        toggleWishlist();
      }}
    >
      <WishlistHeart isInWishlist={isInWishlist} productTile={productTile} />
    </Box>
  );
}

export default WishlistButton;
