import { Box } from "@chakra-ui/react";
import Link from "next/link";
import React, { useState } from "react";
import { generatePath } from "react-router";
import { ReactSVG } from "react-svg";

import { P } from "@components/atoms/Text/P";
import { paths } from "@paths";

import { IItem, IProps } from "./types";

import arrow from "images/arrow-right-new.svg";
import minus from "images/minus-new.svg";
import plus from "images/plus_new.svg";

const getItemPath = (item: IItem) => {
  const { url, category, collection, page } = item;
  if (url) return url;

  if (category) {
    return generatePath(paths.category, { slug: category.slug });
  }
  if (collection) {
    return generatePath(paths.collection, { slug: collection.slug });
  }
  if (page) {
    return generatePath(paths.page, { slug: page.slug });
  }
};

export const MobileNavMainMenuItem: React.FC<IProps> = ({
  item,
  setActiveMenu,
  isParent,
  hideNav,
  textTransform = "uppercase",
}) => {
  const { name, children, legacyUrl } = item;
  const [activeItem, setActiveItem] = useState();

  const path = legacyUrl || getItemPath(item);
  const isLinkItem = path && !children?.length;

  const handleClick = () => {
    setActiveMenu?.(item);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        textAlign="left"
        color="black"
        p="0rem 1.2rem"
        onClick={() => hideNav?.()}
      >
        <P
          fontSize="1.2rem"
          textTransform={textTransform}
          justifyContent="space-between"
          display="flex"
        >
          {isLinkItem ? (
            <Link passHref href={path}>
              <a>{name}</a>
            </Link>
          ) : (
            <Box w="100%">
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  if (isParent) {
                    handleClick();
                  } else {
                    setActiveItem((_activeItem) => (_activeItem ? null : item));
                  }
                }}
                display="flex"
                w="100%"
                justifyContent="space-between"
              >
                <Box>{name}</Box>
                <Box w="0.8rem" cursor="pointer" my="auto">
                  {isParent ? (
                    <ReactSVG src={arrow.src} />
                  ) : activeItem ? (
                    <ReactSVG src={minus.src} />
                  ) : (
                    <ReactSVG src={plus.src} />
                  )}
                </Box>
              </Box>
              {activeItem && (
                <Box as="ul" ml="2rem" mt="1rem">
                  {activeItem.children?.map((_item) => (
                    <MobileNavMainMenuItem item={_item} textTransform="none" />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </P>
      </Box>
    </>
  );
};
