import styled from "@emotion/styled";

import { minMedia } from "@styles";

export const Thumbnail = styled.div`
  min-width: 170px;
  height: 230px;
  justify-content: center;
  justify-items: center;
  display: flex;

  img {
    position: relative;
  }

  ${minMedia.mediumScreen`
  height: 400px;
`};
`;

export const Box = styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  mx: auto;
  width: 166px;
  justify-self: center;
  position: relative;

  ${minMedia.mediumScreen`
    width: 290px;
    height: inherit;
`};
`;

export const Designer = styled.p`
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
  padding: 0;
  width: 70%;
  font-size: 0.95em;
  line-height: 1.05rem;
  display: box;
  line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;

  ${minMedia.mediumScreen`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `};
`;
