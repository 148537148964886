import { Box, Grid, GridItem } from "@chakra-ui/react";
import { FormattedMessage } from "@react-intl";
import { isEmpty } from "lodash";
import Link from "next/link";
import React from "react";
import { generatePath } from "react-router";

import { ProductTile } from "@components/molecules";
import { NoRecommendedProducts } from "@components/organisms/ProductRecommendations";
import { paths } from "@paths";

import { ListProduct, Title } from "./styles";
import { YAMLProps } from "./types";

export const ProductYMAL: React.FC<YAMLProps> = ({ products }) => {
  const hasProducts = !isEmpty(products);

  return (
    <ListProduct>
      <Box
        sx={{
          width: 1400,
          maxWidth: "100vw",
          margin: "0 auto",
        }}
      >
        {hasProducts && (
          <Title>
            <FormattedMessage defaultMessage="You may also like" />
          </Title>
        )}

        {hasProducts ? (
          <Grid
            templateColumns={{
              base: "repeat(1,auto)",
              xs: "repeat(2,auto)",
              lg: "repeat(3,auto)",
              xl: "repeat(4,auto)",
            }}
            rowGap={{ base: 8 }}
            columnGap={{ base: 3 }}
            justifyContent="center"
            alignItems="center"
            marginTop={8}
          >
            {products.map(({ node }) => (
              <GridItem key={`${node.slug}-${node.id}`}>
                <Link href={generatePath(paths.product, { slug: node.slug })}>
                  <ProductTile blackTheme product={node} as="h3" />
                </Link>
              </GridItem>
            ))}
          </Grid>
        ) : (
          <NoRecommendedProducts
            buttonVariant="outline"
            sx={{
              alignItems: "center",
              color: "white",
              display: "flex",
              flexDirection: "column",
            }}
          />
        )}
      </Box>
    </ListProduct>
  );
};
