import { FormattedMessage, useIntl } from "@react-intl";
import { useAuth } from "@the-volte/svc-core-sdk";
import React from "react";

import { Attribute, Tile } from "@components/atoms";
import { commonMessages } from "@temp/intl";

import * as S from "./styles";

export const AccountTile: React.FC = () => {
  const intl = useIntl();
  const { user } = useAuth();

  return (
    <S.TileWrapper>
      <Tile>
        <S.Wrapper>
          <S.Content>
            <S.HeaderSmall>
              <FormattedMessage defaultMessage="Personal details" />
            </S.HeaderSmall>
            <S.ContentOneLine data-test="personalDetailsSection">
              <Attribute
                description={intl.formatMessage(commonMessages.firstName)}
                attributeValue={(user && user.firstName) || "-"}
                testingContext="firstNameText"
              />
              <Attribute
                description={intl.formatMessage(commonMessages.lastName)}
                attributeValue={(user && user.lastName) || "-"}
                testingContext="lastNameText"
              />
            </S.ContentOneLine>
          </S.Content>
        </S.Wrapper>
      </Tile>
    </S.TileWrapper>
  );
};
