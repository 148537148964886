import styled from "@emotion/styled";

import { media } from "@styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const TileWrapper = styled.div`
  height: auto;
  margin-bottom: 1.5rem;
`;

export const HeaderSmall = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: none;
  font-weight: ${(props) => props.theme.typography.boldFontWeight};
  font-size: ${(props) => props.theme.typography.h4FontSize};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
`;

export const Content = styled.div`
  padding: 1.5rem 0;
  width: 95%;
  a {
    display: flex;
    align-items: center;
  }
  a > * {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`;

export const ContentOneLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  ${media.smallScreen`
    flex-direction: column;
    width: 100%;
  `}
`;
