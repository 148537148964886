import { Box, Divider } from "@chakra-ui/react";
import { FormattedMessage } from "@react-intl";
import React, { Fragment, useState } from "react";

import {
  ButtonLink,
  Checkbox,
  FilterSectionWrapper,
  Input,
  Label,
} from "@components/atoms";
import { defaultTheme } from "@styles";

import { IProps } from "./types";

export const AttributeValuesChecklist: React.FC<IProps> = ({
  title,
  name,
  values,
  valuesShowLimit = false,
  valuesShowLimitNumber = 5,
  onValueClick,
  startFrom = 0,
}: IProps) => {
  const [viewAllOptions, setViewAllOptions] = useState(!valuesShowLimit);
  const [searchString, setSearchString] = useState("");

  const onSearch = (e) => {
    setSearchString(e.target?.value.toLowerCase());
  };

  const filteredValues = searchString
    ? values.filter((v) => v.name.toLowerCase().includes(searchString))
    : values;

  return (
    <FilterSectionWrapper title={title} name={name}>
      {name === "designer" && (
        <Input placeholder="Search" onChange={onSearch} />
      )}
      {filteredValues?.length ? (
        filteredValues.map((value, index) =>
          (!viewAllOptions && index - startFrom > valuesShowLimitNumber - 1) ||
          (!viewAllOptions && index === startFrom - 1) ? (
            <Fragment key={index} />
          ) : (
            <Checkbox
              dataTest={`${title}-${value.name}`}
              key={index}
              name={name}
              checked={!!value.selected}
              onChange={() => onValueClick(value)}
            >
              {value && value.name}
            </Checkbox>
          )
        )
      ) : (
        <Label>No match</Label>
      )}
      {!viewAllOptions && filteredValues.length > valuesShowLimitNumber && (
        <Box pb={5}>
          <ButtonLink
            testingContext="viewAllButton"
            size="sm"
            color="secondary"
            onClick={() => setViewAllOptions(true)}
          >
            <FormattedMessage defaultMessage="VIEW ALL OPTIONS" />
          </ButtonLink>
        </Box>
      )}
      <Divider color={defaultTheme.colors.divider} />
    </FilterSectionWrapper>
  );
};
