/* eslint-disable import/no-unresolved */
import React from "react";

import { PlaceholderImage } from "@components/atoms";
import { maybe } from "@utils/misc";

import { NextImage } from "..";
import { IProps } from "./types";

export const Thumbnail: React.FC<IProps> = ({
  source,
  children,
  heightPixels,
  widthPixels,
  priority = false,
  ...props
}: IProps) => {
  const { thumbnail, thumbnail2x } = source;

  if (!thumbnail2x) {
    return <PlaceholderImage />;
  }

  return (
    <NextImage
      {...props}
      url={thumbnail2x!.url}
      alt={maybe(() => (thumbnail!.alt ? thumbnail!.alt : ""), "")}
      heightPixels={heightPixels}
      widthPixels={widthPixels}
      priority={priority}
    >
      {children}
    </NextImage>
  );
};
