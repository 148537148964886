/* eslint-disable import/no-unresolved */
import { Image } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

import { Rating } from "@components/atoms";

// import { NextImage } from "../NextImage";
import {
  LenderContainer,
  ProductLenderContainer,
  ProductLenderInnerContainer,
  ProductListHeaderContainer,
  ProductListHeaderText,
  // StyledLender,
  // StyledLenderImageContainer,
  StyledLenderLink,
  StyledLenderName,
} from "./styles";
import { ProductLenderProps } from "./types";

export const ProductLender: React.FC<ProductLenderProps> = ({
  lender,
  numberOfProducts,
}) =>
  lender ? (
    <div className="container">
      {/* <StyledLender>
        <li>
          <StyledLenderName>{lender.name}</StyledLenderName>
        </li>
        <li>
          {lender.avatar?.url && (
            // <Image src={lender.avatar?.url} />
            // <Box position="relative" height="50px" width="50px">
            <StyledLenderImageContainer>
              <NextImage
                heightPixels={50}
                widthPixels={50}
                alt="Lender avatar"
                url={lender.avatar?.url ? lender.avatar?.url : ""}
              />
            </StyledLenderImageContainer>

            // </Box>
          )}
        </li>
        <li>
          <Rating size={32} scale={5} rate={lender.rating} fillColor="#000" />
        </li>
      </StyledLender> */}
      <LenderContainer>
        {/* <StyledLenderImageContainer>
          <NextImage
            heightPixels={50}
            widthPixels={50}
            alt="Lender avatar"
            url={lender.avatar?.url ? lender.avatar?.url : ""}
          />
        </StyledLenderImageContainer> */}
        {lender?.avatar?.url && (
          <Image
            margin="auto"
            maxWidth="max-content"
            height="auto"
            boxSize="80px"
            alt={`${lender.name} Profile Image`}
            src={lender.avatar?.url}
          />
        )}
        <StyledLenderName>{lender.name}</StyledLenderName>
        {/* <Box
          margin="0 auto"
          maxWidth="fit-content"
          minWidth="200px"
          paddingY="1rem"
          display="flex"
          flexDirection="row"
          gap="1rem"
          fontSize={18}
          alignItems="center"
        > */}
        <ProductLenderContainer>
          <Rating
            // @ts-ignore
            size={32}
            // scale={5}
            rate={lender.rating}
            fillColor="#000"
            showText
          />
          <ProductLenderInnerContainer>
            {lender.lenderOrdersMade
              ? lender.lenderOrdersMade >= 3 && (
                  <ProductListHeaderContainer>
                    <ProductListHeaderText>
                      {lender.lenderOrdersMade}
                    </ProductListHeaderText>
                    {lender.lenderOrdersMade === 1 ? (
                      <p>Order</p>
                    ) : (
                      <p>Orders</p>
                    )}
                  </ProductListHeaderContainer>
                )
              : null}

            <ProductListHeaderContainer>
              <ProductListHeaderText>{numberOfProducts}</ProductListHeaderText>
              {numberOfProducts === 1 ? (
                <p>Item to Rent</p>
              ) : (
                <p>Items to Rent</p>
              )}
            </ProductListHeaderContainer>
          </ProductLenderInnerContainer>
        </ProductLenderContainer>
        {/* </Box> */}
      </LenderContainer>
      <StyledLenderLink>
        <Link passHref href={`/users/${lender.slug}`}>
          <a>SHOW CLOSET</a>
        </Link>
      </StyledLenderLink>
    </div>
  ) : null;
