/* eslint-disable import/no-unresolved */
import Image from "next/image";
import React from "react";

import { PlaceholderImage } from "@components/atoms";
import { greyDataPixel, imageLoader } from "@utils/images";

interface IProps {
  url: string;
  alt: string;
  heightPixels?: number;
  widthPixels?: number;
  priority?: boolean;
  contain?: boolean;
  nofill?: boolean;
  imageQuality?: number;
  fetchPriority?: "high" | "low";
}
export const NextImage: React.FC<IProps> = ({
  url,
  alt,
  heightPixels,
  widthPixels,
  priority = false,
  contain,
  nofill,
  imageQuality = 75,
  fetchPriority,
}) => {
  const [isUnavailable, setUnavailable] = React.useState(false);

  const noFillProps = nofill
    ? {
        width: `${widthPixels}px`,
        height: `${heightPixels}px`,
      }
    : { layout: "fill" };

  if (!url || isUnavailable) {
    return <PlaceholderImage alt={alt} heightPixels={heightPixels} />;
  }

  return (
    <Image
      // NOTES img element has fetchPriority prop, but currently on our version of nextjs it is not defined in the types. fetchPriority was added on Next.js 13.3.0 https://github.com/vercel/next.js/discussions/39740#discussioncomment-6853256
      // @ts-expect-error
      fetchPriority={fetchPriority}
      quality={imageQuality}
      loader={imageLoader}
      src={url}
      alt={alt}
      sizes="30vw"
      // navigator.onLine is not always accurate
      onError={() => setUnavailable(true)}
      objectFit={contain ? "contain" : "cover"}
      placeholder="blur"
      priority={priority}
      blurDataURL={`data:image/png;base64,${greyDataPixel}`}
      {...noFillProps}
    />
  );
};
