import { Box } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import { ReactSVG } from "react-svg";

import { Button } from "@components/atoms";
import { paths } from "@paths";

import { IProps } from "./types";

import accountSettingsImg from "images/account-settings.svg";
import addressBookImg from "images/address-book.svg";
import lenderDashdoardImg from "images/lender-dashboard.svg";
import messagesImg from "images/messages.svg";
import rentersDashboardImg from "images/renters-dashboard.svg";
import wishlistImg from "images/wishlist-heart.svg";

const accountMenuLinks = ({ lenderNumber, messagesNumber }) => [
  {
    name: "MY BORROWING / PURCHASES",
    icon: rentersDashboardImg,
    link: paths.accountOrderHistory,
  },
  {
    name: "MESSAGES",
    icon: messagesImg,
    number: messagesNumber,
    link: paths.accountMessages,
  },
  {
    name: "WISHLIST",
    icon: wishlistImg,
    link: paths.wishlist,
  },
  {
    name: "ADDRESS BOOK",
    icon: addressBookImg,
    link: paths.accountAddressBook,
  },
  {
    name: "LENDER DASHBOARD",
    icon: lenderDashdoardImg,
    number: lenderNumber,
    link: paths.dashboard,
    isExternal: true,
  },
  {
    name: "ACCOUNT SETTINGS",
    icon: accountSettingsImg,
    link: paths.account,
  },
];

export const MobileNavAccountTools: React.FC<IProps> = ({
  user,
  signOut,
  hideNav,
}) => {
  const handleClickSignOut = () => {
    signOut();
    hideNav();
  };

  return (
    <Box
      as="ul"
      display="flex"
      flexDirection="column"
      height="full"
      bg="primary"
      px="1.3rem"
    >
      {accountMenuLinks({
        lenderNumber: user?.lenderOrders?.totalCount,
        messagesNumber: user?.unreadMessagesCount,
      }).map((item) => (
        <Box as="li" display="flex" w="full" p="1.1rem 0" alignItems="center">
          <Box mr="1rem" width="20px">
            <ReactSVG src={item.icon.src} />
          </Box>
          {item.isExternal ? (
            <a href={item.link}>{item.name}</a>
          ) : (
            <Link href={item.link}>{item.name}</Link>
          )}
          {(item?.number || item?.number === 0) && (
            <Box ml="auto" color="tertiary">
              ({item.number})
            </Box>
          )}
        </Box>
      ))}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="end"
        flex={1}
        mb="2rem"
      >
        <Button
          size="sm"
          variant="ternary"
          w="100%"
          mt="0.5rem"
          onClick={handleClickSignOut}
        >
          SIGN OUT
        </Button>
      </Box>
    </Box>
  );
};
