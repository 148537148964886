import { useTheme } from "@emotion/react";
import React from "react";
import { ReactSVG } from "react-svg";

import heartSVG from "../../../images/heart.svg";

type WishlistHeartType = {
  isInWishlist: boolean;
  productTile: boolean;
};

export const WishlistHeart = ({
  isInWishlist,
  productTile,
}: WishlistHeartType) => {
  const theme = useTheme();

  const heartSize = productTile ? 32 : 48;
  const iconStyle: React.CSSProperties = {
    width: heartSize,
    height: heartSize,
    cursor: "pointer",
    strokeWidth: 20,
  };

  return isInWishlist!! ? (
    <ReactSVG
      src={heartSVG.src}
      css={{
        ...iconStyle,
        ...theme.colors.components.wishlistHeart.liked,
      }}
    />
  ) : (
    <ReactSVG
      src={heartSVG.src}
      css={{
        ...iconStyle,
        ...theme.colors.components.wishlistHeart.unliked,
      }}
    />
  );
};
