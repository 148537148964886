import {
  ProductDetails_attributes,
  ProductDetails_attributes_values,
} from "@the-volte/svc-core-sdk/lib/fragments/gqlTypes/ProductDetails";
import { useMemo } from "react";

enum ItemConditionCodes {
  NEW = "new",
  USED = "used",
  NEW_TAGS = "new-with-tags",
}

type ProductAttributeProps = {
  attributes: ProductDetails_attributes[];
};

const useItemCondition = ({ attributes }: ProductAttributeProps) => {
  const itemCondition = useMemo(
    () => attributes.find((attr) => attr.attribute.slug === "condition"),
    [attributes]
  );

  const itemConditionDisplayValue = (
    value: ProductDetails_attributes_values | null
  ) => {
    if (!value) return null;

    const valueSlug = value.slug;

    if (valueSlug === ItemConditionCodes.NEW) {
      return "New (Without Tags)";
    }

    if (valueSlug === ItemConditionCodes.NEW_TAGS) {
      return "New (With Tags)";
    }

    return "Used (Preloved)";
  };

  return { itemCondition, itemConditionDisplayValue };
};

export default useItemCondition;
