import React, { useEffect, useState } from "react";

import { FilterSectionWrapper } from "@components/atoms";
import { IFormError } from "@types";
import { DeliveryFilterType } from "@utils/filterData";

import { TextField } from "../TextField";

interface DeliveryDateFilterInputType {
  deliveryFilter: DeliveryFilterType;
  setDeliveryFilter: (obj: DeliveryFilterType) => void;
}

export const DeliveryFilterInput: React.FC<DeliveryDateFilterInputType> = ({
  deliveryFilter,
  setDeliveryFilter,
}) => {
  const [dateErrors, setDateErrors] = useState<IFormError[]>([]);
  const [postCodeErrors, setPostCodeErrors] = useState<IFormError[]>([]);
  const [dateValue, setDateValue] = useState<string>("");
  const [isDatePicker, setIsDatePicker] = useState<boolean>(false);
  const [postCodeValue, setPostCodeValue] = useState<string>("");

  const getToday = () => new Date().toISOString().substring(0, 10);

  const handleDateChange = (value: string): void => {
    if (!value) {
      setDeliveryFilter({
        ...deliveryFilter,
        date: "",
      });
      setDateErrors([]);
    } else {
      const deliveryDate = Date.parse(value);

      if (!isNaN(deliveryDate)) {
        if (deliveryDate - Date.now() < 0) {
          setDateErrors([{ message: "Choose a date in the future" }]);
        } else {
          setDeliveryFilter({
            ...deliveryFilter,
            date: new Date(deliveryDate).toISOString().substring(0, 10),
          });
          setDateErrors([]);
        }
      } else {
        setDateErrors([{ message: `${value} is not a valid date` }]);
      }
    }
  };
  const handlePostCodeChange = (value: string): void => {
    const newValue = value.trim() || "";
    const isPostCode = /^[0-9]{4}$/.test(newValue);
    if (!newValue || isPostCode) {
      setDeliveryFilter({
        ...deliveryFilter,
        postCode: newValue,
      });
      setPostCodeErrors([]);
    } else {
      setPostCodeErrors([
        {
          message: `${newValue} is not a valid post code`,
        },
      ]);
    }
  };

  const clearPostCode = deliveryFilter.postCode
    ? () =>
        setDeliveryFilter({
          ...deliveryFilter,
          postCode: "",
        })
    : null;

  useEffect(() => {
    setDateValue(deliveryFilter.date);
    setPostCodeValue(deliveryFilter.postCode);
    if (!deliveryFilter.date) {
      setIsDatePicker(false);
    }
  }, [deliveryFilter]);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    handler: (string) => void
  ) => {
    handler(e.target.value);
  };

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    handler: (value: string) => void
  ) => {
    if (e.key === "Enter") {
      handler(e.currentTarget.value);
    }
  };

  return (
    <FilterSectionWrapper title="Delivery">
      <TextField
        type={isDatePicker ? "date" : "text"}
        noSpacer
        errors={dateErrors}
        placeholder="Delivery Date"
        onChange={(e) => handleDateChange(e.target.value)}
        onFocus={() => setIsDatePicker(true)}
        value={dateValue}
        inlineError
        min={getToday()}
      />
      <TextField
        type="tel"
        noSpacer
        errors={postCodeErrors}
        placeholder="Post Code"
        maxLength={4}
        onBlur={(e) => handleBlur(e, handlePostCodeChange)}
        onKeyUp={(e) => handleKeyUp(e, handlePostCodeChange)}
        onChange={(e) => setPostCodeValue(e.target.value)}
        value={postCodeValue}
        inlineError
        onClose={clearPostCode}
      />
    </FilterSectionWrapper>
  );
};
