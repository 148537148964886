import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 6px;
  grid-column-gap: 20px;
  grid-template-columns: 60px auto auto;
  grid-template-areas:
    "photo . ."
    "photo name name"
    "photo . ."
    "photo price .";
`;

export const Photo = styled.div`
  position: relative;
  grid-area: photo;
  height: 100px;
  width: 75px;

  > span {
    border-radius: 4px;
  }
`;
export const Name = styled.div`
  grid-area: name;
  font-size: 16px;
  margin-left: 10px;
`;

export const Price = styled.div`
  grid-area: price;
  text-align: left;
  margin-left: 10px;
  font-size: ${(props) => props.theme.genericLabel.fontSize};
`;
