/* eslint-disable import/no-unresolved */
import { Box } from "@chakra-ui/react";
import { FormattedMessage } from "@react-intl";
import {
  useAddProductToWishlist,
  useAuth,
  useRemoveProductFromWishlist,
} from "@the-volte/svc-core-sdk";
import get from "lodash.get";
import Link from "next/link";
import React, { useState } from "react";
import { generatePath } from "react-router";

import { useTikTokPixelContext } from "@app/providers/TikTokPixelProvider";
import { FreeShippingBadge } from "@components/atoms/FreeShippingBadge";
import { P } from "@components/atoms/Text/P";
import { Money, TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import WishlistButton from "@components/molecules/WishlistButton/WishlistButton";
import useLogin from "@hooks/useLogin";
import { paths } from "@paths";

import * as S from "./styles";
import { IProps } from "./types";

const getAttributeValueByName = (attributes, name) => {
  const designerAttribute = attributes.find((attribute) => {
    if (attribute.attribute.name === name && attribute?.values?.length > 0) {
      return true;
    }

    return false;
  });

  return designerAttribute?.values?.[0]?.name || "";
};

const stripDecimals = (attribute: string) =>
  attribute === "" || isNaN(+attribute)
    ? null
    : parseFloat(attribute).toFixed(0);

export const ProductTile: React.FC<IProps> = ({
  product,
  priority,
  blackTheme,
  index,
  deliveryFilter,
  as,
  hasCollectionSale,
  isWarehouseSaleCollection,
  warehouseSaleTitle,
  productTileWidth,
  thumbnailHeight,
  stackDesignerAndSize = false,
}) => {
  const { attributes, slug, shippingMethods, originalPurchasePrice } = product;
  const { trackProduct } = useTikTokPixelContext();
  const { user } = useAuth();
  const { login } = useLogin();
  const [isInWishlist, setIsInWishlist] = useState<boolean>(() => {
    if (
      user?.wishlist?.edges &&
      product &&
      user.wishlist.edges.some((item) => item.node.productId === product.id)
    ) {
      return true;
    }
    return false;
  });
  const [addProductToWishlist, { loading: addToWishlistLoading }] =
    useAddProductToWishlist(undefined, { refetchQueries: ["UserDetails"] });
  const [removeProductFromWishlist, { loading: removeFromWishLoading }] =
    useRemoveProductFromWishlist(undefined, {
      refetchQueries: ["UserDetails"],
    });

  const size = attributes ? getAttributeValueByName(attributes, "Size") : null;
  const designer = attributes
    ? getAttributeValueByName(attributes, "Designer")
    : null;
  const retailValue = product.retailValue
    ? stripDecimals(product.retailValue.amount.toString())
    : "";

  const price =
    product.pricing &&
    product.pricing.priceRange &&
    product.pricing.priceRange.start
      ? product.pricing.priceRange.start
      : undefined;
  const wishListLoading = addToWishlistLoading || removeFromWishLoading;

  const toggleWishlist = () => {
    if (user?.id && product) {
      if (isInWishlist) {
        if (!wishListLoading) {
          removeProductFromWishlist({ productId: product.id });
          setIsInWishlist(false);
        }
      } else if (!wishListLoading) {
        trackProduct("AddToWishlist", product, true);
        addProductToWishlist({
          productId: product.id,
          productSlug: product.slug || "",
        });
        setIsInWishlist(true);
      }
    } else {
      login({ addToWishlist: "true" });
    }
  };

  const isProductAvailableForFreeShipping = shippingMethods?.some(
    (shippingMethod) => {
      if (
        shippingMethod?.name === "Express Post" &&
        shippingMethod.price?.amount === 0
      ) {
        return true;
      }
      return false;
    }
  );
  const date = deliveryFilter?.date;

  const getOriginalPurchasePrice = originalPurchasePrice
    ? originalPurchasePrice.amount
    : null;

  const getRetailValue = get(
    product,
    "pricing.priceRange.stop.gross.amount",
    0
  );

  let moneySaved = 0;

  if (
    getOriginalPurchasePrice &&
    getRetailValue &&
    getOriginalPurchasePrice > getRetailValue &&
    hasCollectionSale &&
    isWarehouseSaleCollection &&
    warehouseSaleTitle
  ) {
    moneySaved = getOriginalPurchasePrice - getRetailValue;
  }

  return (
    <S.Box style={{ width: productTileWidth || "" }}>
      <Link
        href={generatePath(
          date
            ? moneySaved
              ? "/item/:slug??deliveryDate=:date&price=purchase"
              : "/item/:slug??deliveryDate=:date?"
            : moneySaved
            ? `${paths.product}??price=purchase`
            : paths.product,
          {
            slug,
            date,
          }
        )}
      >
        <a
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {isProductAvailableForFreeShipping && (
            <Box position="absolute" zIndex={1} top="-26px" left="-28px">
              <FreeShippingBadge />
            </Box>
          )}
          <Box position="relative" data-test={`productThumbnail-${index}`}>
            <S.Thumbnail style={{ height: thumbnailHeight || "" }}>
              <Thumbnail
                source={product}
                priority={priority}
                widthPixels={240}
                heightPixels={400}
              />
            </S.Thumbnail>
            <Box position="absolute" top="0.3rem" right="0.5rem">
              <WishlistButton
                toggleWishlist={toggleWishlist}
                isInWishlist={isInWishlist}
                productTile
              />
            </Box>
          </Box>
          <Box
            justifyContent="space-between"
            display="flex"
            flexDirection="column"
            fontWeight="400"
            height="100%"
          >
            <Box
              display="flex"
              flexDirection={
                stackDesignerAndSize ? "column" : ["column", null, null, "row"]
              }
              justifyContent="space-between"
              alignItems={["left", null, null, "flex-start"]}
              pt="0.6rem"
              color={blackTheme ? "secondary" : "black"}
            >
              <S.Designer
                as={as}
                data-test="productTile"
                style={{ width: stackDesignerAndSize ? "100%" : "" }}
              >
                {designer}
              </S.Designer>

              <P textAlign="left" padding={0}>
                {size && size === "One size" && <>{size}</>}
                {size && size !== "One size" && (
                  <>
                    <FormattedMessage defaultMessage="Size" /> {size}
                  </>
                )}
              </P>
            </Box>
            <Box
              display="flex"
              flexDirection={["column", null, null, "row"]}
              justifyContent="space-between"
              data-test="productPrice"
              textAlign="left"
              mt="0.5rem"
              fontSize={["0.8rem", null, null, null, "1rem"]}
              color={blackTheme ? "secondary" : "black"}
            >
              {!moneySaved ? (
                <Box display="flex">
                  <TaxedMoney taxedMoney={price} showDecimals={false} />
                  &nbsp;
                  <FormattedMessage defaultMessage="Rental" />
                </Box>
              ) : null}
              {moneySaved ? (
                <Box display="flex">
                  <Box fontSize={22} fontWeight={600}>
                    <TaxedMoney
                      taxedMoney={
                        product.pricing &&
                        product.pricing.priceRange &&
                        product.pricing.priceRange.stop
                          ? product.pricing.priceRange.stop
                          : undefined
                      }
                    />
                  </Box>
                  <Box ml={1}>
                    <Money
                      style={{
                        textDecoration: "line-through",
                        color: "grey",
                      }}
                      money={{
                        amount: parseFloat(originalPurchasePrice.amount),
                        currency: price?.gross?.currency || "",
                      }}
                      showDecimals={false}
                    />
                  </Box>
                </Box>
              ) : null}
              <Box
                as="span"
                float="right"
                fontSize={["0.8rem", null, null, null, "1rem"]}
              >
                {retailValue ? (
                  <>
                    <Money
                      money={{
                        amount: parseFloat(retailValue),
                        currency: price?.gross?.currency || "",
                      }}
                      showDecimals={false}
                    />
                    &nbsp;
                    <FormattedMessage defaultMessage="RRP" />
                  </>
                ) : null}
              </Box>
            </Box>
            {moneySaved ? (
              <Box display="flex" paddingTop={1.5} color="red">
                <FormattedMessage defaultMessage="Save" />
                &nbsp;
                <Money
                  money={{
                    amount: moneySaved as number,
                    currency: price?.gross?.currency || "",
                  }}
                  showDecimals
                />
              </Box>
            ) : null}
          </Box>
        </a>
      </Link>
    </S.Box>
  );
};
